import _ from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';
import { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { timestamp } from 'src/utils/helper';
import { GET } from 'src/utils/services';

const DynamicAds = dynamic(import('src/utils/ads/dynamic-ads'), { ssr: false });

const Footer = ({ data, ads }) => {
  const router = useRouter();

  const [salesData, setSalesData] = useState(null);
  const [partnersData, setPartnersData] = useState([]);

  useEffect(async () => {
    try {
      const footer = await GET('/api/footer');
      const company = await GET('/api/v1.0/navigations/company-group');
      if (!_.isEmpty(footer)) setSalesData(footer);
      if (!_.isEmpty(company)) setPartnersData(company);
    } catch (err) {
      console.error(`${timestamp()} ==========> FOOTER ERROR : `, err.message);
    }
  }, []);
  return (
    <>
      {/* AD1X1 IN CONTENT */}
      {router.pathname === '/[cid]' && <div className='ads --mb-0'>{!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />}</div>}
      {router.pathname === '/[cid]/[id]' && <div className='ads --mb-0'>{!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />}</div>}

      <FooterWrapper className={`${SHOW_COMMEMORATE === 'true' ? 'back-white' : ''}`}>
        <section className='footer-wrapper'>
          <div className='container'>
            <div className='row'>
              {/* -------------------------------- BLOCK - 1 ------------------------------- */}
              <div className='col-lg-4 col-sm-12 col-xs-12 mb-5 mb-md-0'>
                <h3 className='footer-title'>ติดต่อกรุงเทพธุรกิจ</h3>
                {!_.isEmpty(salesData) && (
                  <div
                    className='footer-sale'
                    dangerouslySetInnerHTML={{
                      __html: salesData
                    }}
                  />
                )}
              </div>

              {/* -------------------------------- BLOCK - 2 ------------------------------- */}
              <div className='col-lg-4 col-sm-12 col-xs-12 mb-5 mb-md-0 mt-3 mt-lg-0'>
                <h3 className='footer-title'>หมวดหมู่ข่าว</h3>
                <div className='row footer-category'>
                  {!_.isEmpty(data) &&
                    data.map((item, index) => (
                      <div key={index} className='col-6 col-md-auto'>
                        <a
                          href={`/${item.link}`}
                          //! DATALAYER
                          onClick={() =>
                            sendDataLayerGlobal({
                              type: DATALAYER_TYPE.TRACK_POSITION,
                              router: router?.pathname,
                              section: 'footer',
                              position: 'on:footer',
                              data: {
                                block: '2',
                                title: item?.nameTh,
                                heading: 'หมวดหมู่ข่าว'
                              }
                            })
                          }
                        >
                          {item.nameTh}
                        </a>
                      </div>
                    ))}
                  <div className='col-6 col-md-auto'>
                    <a
                      href='/privacy-policy'
                      //! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          section: 'footer',
                          position: 'on:footer',
                          data: {
                            block: '2',
                            title: 'นโยบายการเป็นส่วนตัว',
                            heading: 'หมวดหมู่ข่าว'
                          }
                        })
                      }
                    >
                      นโยบายการเป็นส่วนตัว
                    </a>
                  </div>
                </div>
              </div>
              {/* -------------------------------- BLOCK - 3 ------------------------------- */}

              <div className='col-lg-4 col-sm-12 col-xs-12 mb-5 mb-md-0 mt-3 mt-lg-0'>
                <h3 className='footer-title'>พาร์ทเนอร์</h3>
                <div className='row footer-category'>
                  {partnersData.map((item, index) => (
                    <div key={index} className='col-4'>
                      <a
                        href={item.link}
                        target='_blank'
                        rel={'noopener noreferrer nofollow'}
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router?.pathname,
                            section: 'footer',
                            position: 'on:footer',
                            data: {
                              block: '3',
                              title: item?.name,
                              heading: 'พาร์ทเนอร์'
                            }
                          })
                        }
                      >
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>

              <div className='col-12 text-center sonp'>
                <a href='https://www.facebook.com/SONPThai/' target='_blank' rel={'noopener noreferrer nofollow'}>
                  <img src={`${APP_IMG}/logo_sonp.png`} alt='sonp' width='210' height='70' />
                </a>
              </div>
            </div>
          </div>
        </section>
        <CopyrightWrapper className='copyright'>
          <div className='container'>
            <span>© 2021 กรุงเทพธุรกิจ มีเดีย จำกัด. All Rights Reserved.</span>
            <span className='truehit' id='truehits_div' />
          </div>
        </CopyrightWrapper>
      </FooterWrapper>
    </>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  .footer-wrapper {
    color: #969696;
    padding: 50px 0;
    width: 100%;
    background-color: #01308b;
    height: 475px;
    @media (max-width: 475px) {
      height: 970px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 14px;
        font-weight: 300;
      }
    }
    .sonp {
      display: block;
      padding-top: 40px;
    }

    .footer-title {
      font-size: 16px;
      font-weight: 500;
      position: relative;
      margin: 0 0 30px;
      text-transform: uppercase;
      color: #fff;
      padding-left: 15px;
      padding-top: 8px;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        content: '';
        background: #b1b1b1;
        left: 0;
        border-radius: 30px;
      }

      &:before {
        width: 3px;
        height: 18px;
      }

      &:after {
        width: 18px;
        height: 3px;
      }
    }

    .footer-sale {
      p {
        margin-left: 10px;
        font-size: 13px !important;
        color: #b1b1b1;

        strong {
          font-size: 15px;
          font-weight: 400;
        }
      }
    }

    .footer-category {
      a {
        cursor: pointer;
        text-decoration: none;
        color: #b1b1b1;
        font-size: 13px !important;
        font-weight: 300;

        &:hover {
          color: #fff !important;
        }
      }

      @media (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }
`;

const CopyrightWrapper = styled.div`
  &.copyright {
    background: #0f0d0d;
    color: #fff;
    position: relative;
    z-index: 1;
    font-weight: 300;
    font-size: 12px;
    span {
      padding: 5px 0;
      display: block;
      text-align: center;
    }
  }
`;
